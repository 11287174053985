import styled from "styled-components";

export const EnsignBeersWrapper = styled.section`
    width: 100%;
    min-height: 80vh;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    background: ${(props) => props.theme.color.white};
    @media screen and (max-width: 769px) {
        display: flex;
        flex-direction: column;
        min-height: 100vh;
    }
    .content {
        padding: 30px 16%;
        h4 {
            font-family: ${(props) => props.theme.font.text};
            font-size: 14px;
            font-weight: ${(props) => props.theme.fontWeight.light};
        }
    }
    h2 {
        font-family: ${(props) => props.theme.font.text};
        font-size: 16px;
        font-variant: small-caps;
    }
    h3 {
        font-family: ${(props) => props.theme.font.title};
        font-size: 30px;
        text-transform: uppercase;
        line-height: 20px;
        font-weight: ${(props) => props.theme.fontWeight.light};
    }
    .graph {
        width: 100%;
        text-decoration: none;
        position: relative;
        padding-top: 30px;
        display: flex;
        flex-direction: column;
        align-items: center;
        .image {
            position: relative;
            padding-bottom: 200px;
            height: 100%;
            width: 100%;
            max-width: 1100px;
            display: flex;
            justify-content: center;
            align-items: center;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        .description {
            margin-top: 25px;
            width: 70%;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            .beerType {
                display: flex;
                justify-content: center;
                margin-bottom: 0px;
                padding-top: 30px;
                font-family: ${(props) => props.theme.font.text};
                text-transform: uppercase;
                font-size: 14px;
                align-self: center;
                height: 100%;
                margin-bottom: 40px;
                @media screen and (max-width: 769px) {
                    margin-bottom: 10px;
                    font-size: 12px;
                }
            }
            .know-more {
                font-family: ${(props) => props.theme.font.text};
                text-transform: uppercase;
                font-size: 14px;
                align-self: center;
                height: 100%;
                margin-bottom: 40px;
                @media screen and (max-width: 769px) {
                    margin-bottom: 10px;
                    font-size: 12px;
                }
            }
            h4 {
                font-family: ${(props) => props.theme.font.text};
                text-align: center;
                font-weight: ${(props) => props.theme.fontWeight.light};
                font-size: 15px;
                @media screen and (max-width: 769px) {
                    font-size: 12px;
                }
            }
            h3 {
                font-family: ${(props) => props.theme.font.title};
                font-size: 35px;
                text-transform: uppercase;
                font-weight: ${(props) => props.theme.fontWeight.light};
                margin: 0px;
                @media screen and (max-width: 769px) {
                    font-size: 25px;
                }
            }
            .price-quantity {
                margin-top: 30px;
                font-family: ${(props) => props.theme.font.text};
                font-weight: ${(props) => props.theme.fontWeight.bold};
                font-size: 16px;
                text-transform: uppercase;
                align-self: center;
                height: 100%;
                margin-bottom: 40px;
                @media screen and (max-width: 769px) {
                    margin-bottom: 10px;
                    font-size: 12px;
                }
            }
        }
        
        .image_hover {
            color: ${(props) => props.theme.color.black};
            opacity: 0%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-around;
            padding: 20% 20%;
            width: 100%;
            height: 100%;
            position: absolute;
            z-index: 50;
            top: 0px;
            backdrop-filter: blur(14px); 
            -webkit-backdrop-filter: blur(14px);
            @supports not ((-webkit-backdrop-filter: blur(10px)) or (backdrop-filter: blur(10px))) {
                .navbar {
                    background-color: rgba(255, 255, 255, 0.6); 
                }
            }
            transition: 0.5s;
            background: rgba(255, 255, 255, 0.4);

            h2 {
                font-size: 15px;
                text-transform: uppercase;
            }
            h4,
            h2 {
                font-family: ${(props) => props.theme.font.text};
                text-align: center;
                font-weight: ${(props) => props.theme.fontWeight.light};
                font-size: 17px;
            }
            h3 {
                font-family: ${(props) => props.theme.font.title};
                font-size: 40px;
                text-transform: uppercase;
                font-weight: ${(props) => props.theme.fontWeight.light};
                margin: 0px;
            }
            .price {
                font-family: ${(props) => props.theme.font.title};
                font-size: 30px;
                text-align: center;
            }
            .quantity {
                font-family: ${(props) => props.theme.font.text};
            }
            div {
                display: flex;
                flex-direction: column;
            }
        }
        &:hover {
            .image_hover {
                opacity: 100%;
            }
        }
        .responsive_beer_button {
            top: 5%;
            padding: 15px 18px;
            margin: 15px 0px;
            width: fit-content;
            border: 1px solid black;
            border-radius: 50px;
            color: black;
            font-family: ${(props) => props.theme.font.text};
            font-size: 14px;
        }
        /* .image_hover {
            color: ${(props) => props.theme.color.black};
            opacity: 0%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-around;
            padding: 20% 20%;
            width: 100%;
            height: 100%;
            position: absolute;
            z-index: 50;
            top: 0px;
            backdrop-filter: blur(14px);
            transition: 0.5s;
            background: rgba(255, 255, 255, 0.4);

            h2 {
                font-size: 15px;
                text-transform: uppercase;
            }
            h4,
            h2 {
                font-family: ${(props) => props.theme.font.text};
                text-align: center;
                font-weight: ${(props) => props.theme.fontWeight.light};
                font-size: 17px;
            }
            h3 {
                font-family: ${(props) => props.theme.font.title};
                font-size: 40px;
                text-transform: uppercase;
                font-weight: ${(props) => props.theme.fontWeight.light};
                margin: 0px;
            }
            .price {
                font-family: ${(props) => props.theme.font.title};
                font-size: 30px;
                text-align: center;
            }
            .quantity {
                font-family: ${(props) => props.theme.font.text};
            }
            div {
                display: flex;
                flex-direction: column;
            }
        }
        .image {
            position: relative;
            padding-bottom: 100px;
            height: 100%;
            width: 100%;
            max-width: 1100px;
            display: flex;
            justify-content: center;
            align-items: center;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        &:hover {
            .image_hover {
                opacity: 100%;
            }
        }
    } */

    .coming {
        font-family: "Roboto Mono";
        font-size: 14px;
        font-weight: 200;
    }

    /* @media screen and (max-width: 769px) {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr 1fr;
        .content {
            padding: 30px 5%;
        }
        h3 {
            br {
                display: none;
            }

            line-height: 30px;
        }
    } */
`;
