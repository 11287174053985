import { LightButton } from "@/components/LightButton/LightButton";
import Image from "next/image";
import React from "react";
import styled from "styled-components";

const FactoryWrapper = styled.section`
	width: 100%;
	display: flex;
	min-height: 90vh;
	.graph {
		width: 50%;
		height: 90vh;
		position: relative;

		img {
			z-index: 1;
		}
		h2,
		h3,
		h4 {
			z-index: 20;
			position: relative;
			color: ${(props) => props.theme.color.white};
			margin-left: 10%;
			margin-right: 10%;
			font-weight: ${(props) => props.theme.fontWeight.light};
		}

		h2 {
			font-family: ${(props) => props.theme.font.text};
			font-size: 16px;
			 font-variant: small-caps;
		
			margin-top: 57vh;
		}
		h3 {
			font-family: ${(props) => props.theme.font.title};
			font-size: 30px;
			text-transform: uppercase;
			line-height: 20px;
		}
		h4 {
			font-family: ${(props) => props.theme.font.text};
			font-size: 14px;
		}
		a {
			margin: 0% 10%;
		}
	}
	@media screen and (max-width: 769px) {
		flex-direction: column;
		.graph {
			width: 100%;
			height: 100vh;
			h2,
		h3,
		h4, a {
			margin-left: 5%;
			margin-right: 5%;
		}
		}
		
	}
`;

export const FactoryBanner = ({ data }: any) => {
	return (
		<FactoryWrapper>
			<div className="graph">
				<Image
					src={`${process.env.NEXT_PUBLIC_STRAPI_URL}${data?.factory_banner_image_1?.data?.attributes?.url}`}
					alt={
						data?.factory_banner_image_1?.data?.attributes?.alternativeText ||
						"Fábrica El Secreto Brewing"
					}
					fill
					priority
					style={{ objectFit: "cover" }}
				/>
				<h2>{data?.factory_banner_pretitle_1}</h2>
				<h3
					dangerouslySetInnerHTML={{ __html: data?.factory_banner_title }}></h3>
				<h4
					dangerouslySetInnerHTML={{
						__html: data?.factory_banner_text_1
					}}></h4>
				<LightButton link={data?.factory_banner_action_button_link_1 || "/"}>
					{data?.factory_banner_action_button_1}
				</LightButton>
			</div>
			<div className="graph">
				<Image
					src={`${process.env.NEXT_PUBLIC_STRAPI_URL}${data?.factory_banner_image_2?.data?.attributes?.url}`}
					alt={
						data?.factory_banner_image_2?.data?.attributes?.alternativeText ||
						"Fábrica El Secreto Brewing"
					}
					fill
					priority
					style={{ objectFit: "cover" }}
				/>
				<h2 style={{ marginTop: "65vh" }}>{data?.factory_banner_pretitle_2}</h2>
				<h4
					dangerouslySetInnerHTML={{
						__html: data?.factory_banner_text_2
					}}></h4>
				<LightButton link={data?.factory_banner_action_button_link_2 || "/"}>
					{data?.factory_banner_action_button_2}
				</LightButton>
			</div>
		</FactoryWrapper>
	);
};
