import styled from "styled-components";

export const HistoryBannerWrapper = styled.section`
	width: 100%;
	min-height: 100vh;
	display: grid;
	grid-template-columns: 1fr 1fr;
	background: ${(props) => props.theme.color.white};
	.content {
		padding: 80px 16%;
		h2 {
			font-family: ${(props) => props.theme.font.text};
			font-size: 16px;
			 font-variant: small-caps;
			
		}
		h3 {
			font-family: ${(props) => props.theme.font.title};
			font-size: 30px;
			text-transform: uppercase;
			line-height: 20px;
			margin: 40px 0px;
			font-weight: ${(props) => props.theme.fontWeight.light};
		}
		h4 {
			font-family: ${(props) => props.theme.font.text};
			font-size: 14px;
			font-weight: ${(props) => props.theme.fontWeight.light};
		}
	}
	.graph {
		position: relative;
	}
	@media screen and (max-width: 769px) {
		grid-template-columns: 1fr;
		grid-template-rows: 0.5fr 1fr;
		.content {
			padding: 30px 5%;
			h3 {
				line-height: 40px;
			}
			
		}
		
		
		.graph {
		position: relative;
		img{
			object-position: -10px;
		}
	}

	}
`;
