import React from "react";
import { EnsignBeersWrapper } from "./EnsignBeers.style";
import Image from "next/image";
import { LightButton } from "@/components/LightButton/LightButton";
import { useRouter } from "next/router";
import Link from "next/link";
import { createSlug } from "@/utils/create-slug";
import { useWindowSize } from "@uidotdev/usehooks";

export const EnsignBeers = ({ data, beers }: { data: any; beers: any }) => {
    const { locale } = useRouter();
    const { width } = useWindowSize();

    return (
        <EnsignBeersWrapper>
            <div className="content">
                <h2>{data?.ensign_beers_pretitle}</h2>
                <h3 dangerouslySetInnerHTML={{ __html: data?.ensign_beers_title }}></h3>
                <h4 dangerouslySetInnerHTML={{ __html: data?.ensign_beers_text }}></h4>
                <LightButton link={data?.ensign_beers_action_button_link} dark={true}>
                    {data?.ensign_beers_action_button}
                </LightButton>
            </div>
            {beers?.length > 0 &&
                beers
                    ?.filter((beer: any) => beer?.is_ensign_beer)
                    ?.slice(0, 2)
                    ?.map((beer: any, index: any) => {
                        return (
                            <Link
                                href={`shop/${createSlug(beer?.name)}`}
                                className="graph"
                                key={index}
                                style={{ background: `${beer?.background_color}` }}
                            >
                                <div className="image">
                                    <Image
                                        src={`${process.env.NEXT_PUBLIC_STRAPI_URL}${beer?.picture_with_background?.data?.attributes?.url}`}
                                        alt={data?.ensign_beers_left_image?.data?.attributes?.alternativeText || ""}
                                        priority
                                        fill
                                    />
                                </div>
                                <div className="description" style={{ color: `${beer?.font_color}` }}>
                                    <span className="beerType">{beer?.beer_type}</span>
                                    <h3
                                        dangerouslySetInnerHTML={{
                                            __html: beer?.name,
                                        }}
                                    ></h3>
                                    <h4
                                        dangerouslySetInnerHTML={{
                                            __html: beer?.short_description,
                                        }}
                                    ></h4>
                                    {beer?.is_purchasable ? (
                                        <>
                                            <div className="price-quantity">
                                                <span className="price">{`${beer?.price}€ - `}</span>
                                                <span className="quantity">{`${beer?.quantity_text}`}</span>
                                            </div>
                                            {width && width < 769 ? (
                                                <div
                                                    className="responsive_beer_button"
                                                    style={{ color: `${beer?.font_color}`, borderColor: `${beer?.font_color}` }}
                                                >
                                                    {`${
                                                        locale === "es"
                                                            ? "Descubre"
                                                            : locale === "en"
                                                            ? "Discover"
                                                            : locale === "fr"
                                                            ? "Découvrir"
                                                            : "Descobreix"
                                                    } ${beer?.name}`}
                                                </div>
                                            ) : (
                                                <LightButton
                                                    color={beer?.font_color}
                                                    className="know-more"
                                                    noColorHover
                                                    link={`shop/${createSlug(beer?.name)}`}
                                                >
                                                    {locale === "es"
                                                        ? "Saber más"
                                                        : locale === "en"
                                                        ? "Learn more"
                                                        : locale === "fr"
                                                        ? "Savoir plus"
                                                        : "Saber més"}
                                                </LightButton>
                                            )}
                                        </>
                                    ) : (
                                        <span>- Coming soon --</span>
                                    )}
                                </div>

                                {width && width > 769 && beers && beers?.length > 0 && (
                                    <div className="image_hover">
                                        <h2>{beer?.beer_type}</h2>
                                        <h3
                                            dangerouslySetInnerHTML={{
                                                __html: beer?.name,
                                            }}
                                        ></h3>
                                        <h4
                                            dangerouslySetInnerHTML={{
                                                __html: beer?.hover_description,
                                            }}
                                        ></h4>
                                        <div className="price-quantity">
                                            <span className="price">{`${beer?.price}€`}</span>
                                            <span className="quantity">{`${beer?.quantity_text}`}</span>
                                        </div>

                                        <LightButton dark noColorHover style={{ fontWeight: "800" }} link={`shop/${createSlug(beer?.name)}`}>
                                            {locale === "es"
                                                ? "Saber más"
                                                : locale === "en"
                                                ? "Learn more"
                                                : locale === "fr"
                                                ? "Savoir plus"
                                                : "Saber més"}
                                        </LightButton>
                                    </div>
                                )}
                                {/* 
                                {width && width < 769 && (
                                    <div className="responsive_beer_button">{`${locale === "es" ? "Conoce" : "Coneix"} ${beer?.name}`}</div>
                                )}
                                {beers && beers?.length > 0 && (
                                    <div className="image_hover">
                                        <h2>{beer?.beer_type}</h2>
                                        <h3
                                            dangerouslySetInnerHTML={{
                                                __html: beer?.name,
                                            }}
                                        ></h3>
                                        <h4
                                            dangerouslySetInnerHTML={{
                                                __html: beer?.hover_description,
                                            }}
                                        ></h4>
                                        {beer.is_purchasable ? (
                                            <>
                                                <span className="price">{`${beer?.price} €`}</span>
                                                <span className="quantity">{`${beer?.quantity_text}`}</span>
                                                <LightButton noColorHover dark={true} link={`shop/${createSlug(beer?.name)}`}>
                                                    {locale === "es"
                                                        ? "Añadir a la cesta"
                                                        : locale === "en"
                                                        ? "Add to cart"
                                                        : locale === "fr"
                                                        ? "Ajouter au panier"
                                                        : "Afegir a la cistella"}
                                                </LightButton>
                                            </>
                                        ) : (
                                            <span className="coming">- Coming soon -</span>
                                        )}
                                    </div>
                                )} */}
                            </Link>
                        );
                    })}
        </EnsignBeersWrapper>
    );
};
