import React from "react";
import { MerchBannerWrapper } from "./MerchBanner.style";
import Image from "next/image";
import { LightButton } from "@/components/LightButton/LightButton";
import { useWindowSize } from "@uidotdev/usehooks";

export const MerchBanner = ({ data }: any) => {
    const { width } = useWindowSize();
    console.log(data.data?.banner_action_button_link);

    if (width && width < 769) {
        return (
            <MerchBannerWrapper>
                <div className="content">
                    <h2>{data.data?.banner_pretitle}</h2>
                    <h3 dangerouslySetInnerHTML={{ __html: data.data?.banner_title }}></h3>
                    <h4 dangerouslySetInnerHTML={{ __html: data.data?.banner_text }}></h4>
                    <LightButton link={data.data?.banner_action_button_link} dark={true}>
                        {data.data?.banner_action_button}
                    </LightButton>
                </div>
                <div className="graph">
                    <Image
                        src={`${process.env.NEXT_PUBLIC_STRAPI_URL}${data.data?.banner_image_2?.url}`}
                        alt={data.data?.banner_image_2?.data?.attributes?.alternativeText || ""}
                        layout="fill"
                        priority
                        style={{ objectFit: "cover" }}
                    />
                </div>
            </MerchBannerWrapper>
        );
    } else {
        return (
            <MerchBannerWrapper>
                <div className="graph">
                    <Image
                        src={`${process.env.NEXT_PUBLIC_STRAPI_URL}${data.data?.banner_image_1?.url}`}
                        alt={data?.banner_image_1?.data?.attributes?.alternativeText || ""}
                        fill
                        priority
                        style={{ objectFit: "cover" }}
                    />
                </div>
                <div className="graph">
                    <Image
                        src={`${process.env.NEXT_PUBLIC_STRAPI_URL}${data.data?.banner_image_2?.url}`}
                        alt={data?.banner_image_2?.data?.attributes?.alternativeText || ""}
                        fill
                        priority
                        style={{ objectFit: "cover" }}
                    />
                </div>
                <div className="content">
                    <h2>{data.data?.banner_pretitle}</h2>
                    <h3 dangerouslySetInnerHTML={{ __html: data.data?.banner_title }}></h3>
                    <h4 dangerouslySetInnerHTML={{ __html: data.data?.banner_text }}></h4>
                    <LightButton link={data.data?.banner_action_button_link} dark={true}>
                        {data.data?.banner_action_button}
                    </LightButton>
                </div>
            </MerchBannerWrapper>
        );
    }
};
