import React from "react";
import Image from "next/image";
import { HistoryBannerWrapper } from "./HistoryBanner.style";
import { useWindowSize } from "@uidotdev/usehooks";
import { LightButton } from "@/components/LightButton/LightButton";
import { useRouter } from "next/router";

export const HistoryBanner = ({ data }: any) => {
	const { width } = useWindowSize();
	const { locale } = useRouter();
	return (
        <HistoryBannerWrapper>
            <div className="content">
                <h2>{data?.history_banner_pretitle}</h2>
                <h3 dangerouslySetInnerHTML={{ __html: data?.history_banner_title }}></h3>
                <h4
                    dangerouslySetInnerHTML={
                        width && width < 769 ? { __html: data?.history_banner_mobile_text } : { __html: data?.history_banner_text }
                    }
                ></h4>
                {width && width < 769 && (
                    <LightButton link={"/about"} dark={true}>
                        {locale === "es" ? "Saber más" : locale === "en" ? "Learn more" : locale === "fr" ? "Savoir plus" : "Saber més"}
                    </LightButton>
                )}
            </div>
            <div className="graph">
                <Image
                    src={`${process.env.NEXT_PUBLIC_STRAPI_URL}${data?.history_banner_image?.data?.attributes?.url}`}
                    alt={data?.history_banner_image?.data?.attributes?.alternativeText || ""}
                    fill
                    priority
                    style={{ objectFit: "cover" }}
                />
            </div>
        </HistoryBannerWrapper>
    );
};
