import styled from "styled-components";

export const MerchBannerWrapper = styled.section`
	width: 100%;
	min-height: 80vh;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	background: ${(props) => props.theme.color.white};
	.content {
		padding: 100px 18%;
		h2 {
			font-family: ${(props) => props.theme.font.text};
			font-size: 16px;
			 font-variant: small-caps;
			
		}
		h3 {
			font-family: ${(props) => props.theme.font.title};
			font-size: 30px;
			text-transform: uppercase;
			line-height: 20px;
				font-weight: ${(props) => props.theme.fontWeight.light};
		}
		h4 {
			font-family: ${(props) => props.theme.font.text};
			font-size: 14px;
			font-weight: ${(props) => props.theme.fontWeight.light};
			max-width: 80%;
		}
	}
	.graph {
		position: relative;
	}
	@media screen and (max-width: 769px) {
		grid-template-columns: 1fr;
		grid-template-rows: 1fr 1fr;

		.content {
			padding: 30px 5%;
				height: 60vh;
			br{
				display: none;
				
			}
			h3{
				line-height: 30px;
			}
		}
		
	}
`;
