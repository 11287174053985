function createSlug(text: string) {
    return text.normalize("NFD").toLowerCase().replace(/ /g, "-");
}

function getNameFromSlug(slug: string) {
    return slug
        .replace(/-/g, " ")
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .replace(/\b\w/g, (c) => c.toUpperCase());
}

export { createSlug, getNameFromSlug };
