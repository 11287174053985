import React from "react";
import { HomeHeroWrapper } from "./HomeHero.style";
import Image from "next/image";
import { useWindowSize } from "@uidotdev/usehooks";
import { LightButton } from "@/components/LightButton/LightButton";

export const HomeHero = ({ data }: any) => {
    const { width } = useWindowSize();
    return (
        <HomeHeroWrapper>
            <div className="content">
                <h1>{data?.hero_pretitle_1}</h1>
                {width && width > 769 && <LightButton link={data?.hero_action_button_link}>{data?.hero_action_button}</LightButton>}
                <h2>{data?.hero_pretitle_2}</h2>
                {width && width < 769 && <LightButton link={data?.hero_action_button_link}>{data?.hero_action_button}</LightButton>}
                <h3>{data?.hero_title}</h3>
            </div>
            <div className="image">
                <Image
                    src={`${process.env.NEXT_PUBLIC_STRAPI_URL}${data?.hero_image?.data?.attributes?.url}`}
                    alt={data?.hero_image?.data?.attributes?.alternativeText || ""}
                    fill
                    priority
                    style={{ objectFit: "cover" }}
                />
            </div>
        </HomeHeroWrapper>
    );
};
