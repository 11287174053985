import styled from "styled-components";

export const HomeHeroWrapper = styled.section`
	background: ${(props) => props.theme.color.green};
	width: 100%;
	height: 100vh;
	padding-top: 15vh;
	display: flex;
	justify-content: space-between;

	.content {
		width: 35%;
		padding: 0% 5%;
		display: flex;
		flex-direction: column;
		position: relative;
		h1 {
			font-family: ${(props) => props.theme.font.text};
			font-size: 20px;
			color: ${(props) => props.theme.color.darkGreen};
			font-weight: ${(props) => props.theme.fontWeight.light};
		}
		h2 {
			font-family: ${(props) => props.theme.font.text};
			font-weight: ${(props) => props.theme.fontWeight.light};
			font-size: 16px;
			color: ${(props) => props.theme.color.darkGreen};
		}
		h3 {
			font-family: ${(props) => props.theme.font.title};
			font-size: 40px;
			color: ${(props) => props.theme.color.darkGreen};
			position: absolute;
			bottom: 0%;
		}
	}
	.image {
		width: 50%;
		position: relative;
	}
	@media screen and (max-width: 769px) {
		flex-direction: column;
		.content {
			width: 90%;
			height: 80%;
			h1 {
				font-size: 15px;
				font-weight: ${(props) => props.theme.fontWeight.light};
				margin-bottom: 0px;
			}
			h2 {
				font-size: 16px;
				margin-bottom: 0px;
			}
			h3 {
				font-family: ${(props) => props.theme.font.title};
				font-size: 30px;
				margin-top: 20px;
				font-weight: 100;
				bottom: 0px;
			}
		}
		.image {
			width: 100%;
			height: 50%;
		}
	}
`;
