import React from "react";
import styled from "styled-components";
import { IconArrowRight } from "@tabler/icons-react";
import Link from "next/link";

const LightButtonWrapper = styled(Link)<{
    dark: boolean | undefined;
    noColorHover: boolean | undefined;
    hoverColor: string | undefined;
    color: string | undefined;
    blank: boolean | undefined;
}>`
    display: flex;
    align-items: ${(props) => (props.blank ? "end" : "center")};
    position: relative;
    z-index: 20;
    font-size: 14px;
    cursor: pointer;
    text-decoration: none;
    font-family: ${(props) => props.theme.font.text};
    font-weight: ${(props) => props.theme.fontWeight.light};
    color: ${(props) => (props.dark ? props.theme.color.black : props.color ? props.color : props.theme.color.white)};
    transition: 0.3s;
    scroll-behavior: smooth;
    a {
        text-decoration: none;
        color: ${(props) => (props.dark ? props.theme.color.black : props.color ? props.color : props.theme.color.white)};
        span {
            display: flex;
            align-items: center;
        }
    }
    &:hover {
        text-decoration: underline;
    }
`;

export const LightButton = ({
    link,
    children,
    dark,
    blank,
    noColorHover,
    color,
    hoverColor,
    style,
    className,
}: {
    link: string;
    children: any;
    dark?: boolean | undefined;
    blank?: boolean | undefined;
    noColorHover?: boolean | undefined;
    hoverColor?: string | undefined;
    color?: string | undefined;
    style?: any;
    className?: string;
}) => {
    console.log(link);
    return (
        <LightButtonWrapper
            color={color}
            hoverColor={hoverColor}
            href={link || "/"}
            noColorHover={noColorHover}
            dark={dark}
            target={blank ? "_blank" : "_self"}
            rel="noopener noreferrer"
            blank={blank}
        >
            <span style={style} className={className}>
                {children} <IconArrowRight size={20} strokeWidth={1} style={{ marginLeft: 3, marginBottom: -5 }} />
            </span>
        </LightButtonWrapper>
    );
};
